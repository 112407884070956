<!-- You can change the values `dark` here with any of the following: -->
<!-- dark, light, primary, secondary, success, info, danger, warning -->
<nav class="navbar navbar-dark bg-dark">
  <!-- This is the application title with a link to the root -->
  <a class="navbar-brand" routerLinkActive="active" routerLink="/">
    <img
      src="assets/logo-cerus.png"
      height="30"
      class="d-inline-block align-top"
      alt="Angular Starters Logo"
      loading="lazy"
    />
     | Receita Garantida</a>
  <!-- This is a spacer so the links move to the end -->
  <div class="mr-auto"></div>
  <!-- This main navigation links are defined here -->
  <div class="navbar-expand">
    <div class="navbar-nav">
      <!-- Each link has the routerLink property set to a different route -->
      <a class="nav-item nav-link" routerLinkActive="active" routerLink="/home"
        >2ª via de boletos</a>
      <!--<a class="nav-item nav-link" routerLinkActive="active" routerLink="/about"
        >About</a>-->
    </div>
  </div>
</nav>
