import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { HttpInterceptor, HttpRequest } from "@angular/common/http/";
import { HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import { tap, finalize } from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import "rxjs/Rx"; 
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { _throw } from "rxjs/observable/throw";
import { catchError } from "rxjs/operators";
import { environment } from "../environments/environment";
import { pipe } from "rxjs/Rx";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_URL: any = environment.API_URL;
  loading : any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { 

  }


  setLoading(){
    if (this.loading){      
    } else {
      //this.loading = this.message.loading('Processando..', { nzDuration: 0 }).messageId;
    }
    return this.loading;

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let promise = new Promise((resolve, reject) => {                    
      let token = localStorage.getItem("token") || 'cerus_integracao_teste';      
      resolve({token});      
    });

    return Observable.fromPromise(promise).mergeMap((tokens) => {
      let clonedReq = this.addToken(request, tokens);
      return next.handle(clonedReq).pipe(
        tap((response : any)=>{
          let data = response.body;

          if (data && data.success) {
            this.success( data.message );
          }

        }),
        catchError((error) => {

          if (error.status == 400) {
            console.log(error);
            this.error( error.error.message );
            return _throw(error);
          }

          if (error.status == 401) {
            this.router.navigateByUrl("/login");
          } else {
          }

          return _throw(error);
        })
      );
    });
  }

  private addToken(request: HttpRequest<any>, tokens: any) {
    if (tokens) {
      let clone: HttpRequest<any>;

      let HEADERS = {};

      if (request.url.indexOf("****") >= 0) {
        clone = request.clone();
      } else {        
        HEADERS = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: tokens.token || ''
        };
        clone = request.clone({
          setHeaders: HEADERS,
        });
      }

      return clone;
    }
    return request;
  }
  

  get(path: any) {
    return this.http.get(this.API_URL + path);
  }

  getExterno(path: any) {
    return this.http.get(path);
  }

  post(path: any, params: any) {
    return this.http.post(this.API_URL + path, params);
  }  


  execute(ACAO : any, data : any){

    this.setLoading();
    data.ACAO = ACAO;
    return this.post('/execute', data ).pipe(finalize(() => {      
        //this.message.remove( this.loading );
        this.loading = null;
    }))

  }

  postExterno(path: any, params: any) {
    return this.http.post(path, params);
  }  

  put(path: any, params: any) {
    return this.http.put(this.API_URL + path, params);
  }

  delete(path: any, params: any = null) {
    return this.http.delete(this.API_URL + path);
  }

  //MENSAGENS
  error(message){      
    alert(message);
  }

  warning(message){      
    alert(message);
  }

  success(message){      
    alert(message);
  }


}
