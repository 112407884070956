import {LOCALE_ID, DEFAULT_CURRENCY_CODE} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiModule } from './ui/ui.module';

import { ApiService} from "./api-service.service";

registerLocaleData(pt,'pt');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, 
    FormsModule,
    ReactiveFormsModule,
    UiModule
  ],  
  providers: [
    {
        provide: LOCALE_ID,
        useValue: 'pt'
    },{
        provide:  DEFAULT_CURRENCY_CODE,
        useValue: 'BRL'
    },{ 
        provide: HTTP_INTERCEPTORS, useClass: ApiService, multi: true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
