<!-- This flex container takes the full height -->
<div class="d-flex flex-column h-100">
  <app-header></app-header>
  <!-- The main area does not shrink, 'pushing down' the footer -->
  <main class="flex-shrink-0"  >
    <!-- This will render the routes -->
    <router-outlet></router-outlet>
  </main>
  <!-- This keeps the footer down if the main content does not fill up the space -->
  <footer class="mt-auto">
    <app-footer></app-footer>
  </footer>
</div>
